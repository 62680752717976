import Vue from 'vue';
import App from './App.vue';
import router from "./router.js";
import store from "./store";
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue-apexcharts'
import VueQRCodeComponent from 'vue-qrcode-component'
import "bootstrap/dist/css/bootstrap.css";
import "datatables.net-bs4";
import Pagination from 'vue-pagination-2';


import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import vuetify from './plugins/vuetify'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/libs.bundle.css'
import './assets/css/theme.bundle.css'
import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-orgchart/dist/style.min.css'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import GSignInButton from 'vue-google-signin-button';
import number from "@coders-tm/vue-number-format";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import VueHtmlToPaper from 'vue-html-to-paper';
import VueDatepickerUi from 'vue-datepicker-ui'
import Dialog from 'vue-dialog-loading'
import 'bootstrap/dist/js/bootstrap.esm.min.js'
import VueMask from 'v-mask'

import fragment from './plugins/fragment'
import NomorFaktur from './views/payments/nomor-faktur.vue';
import NomorFakturSimple from './views/payments/nomor-faktur-simple.vue';
import Amount from './views/payments/amount.vue';


window.axios = require("axios");
window.queryString = require("query-string");
// window.axios.defaults.baseURL = "";

Vue.prototype.moment = moment
const options = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=yes',
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    './assets/css/mycustom.css'
  ]
}


Vue.config.productionTip = false
Vue.component('v-select', vSelect)
Vue.component('Datepicker', VueDatepickerUi)
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('apexchart', VueApexCharts)
Vue.component('pagination', Pagination);
Vue.component('NomorFaktur', NomorFaktur);
Vue.component('NomorFakturSimple', NomorFakturSimple);
Vue.component('Amount', Amount);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(DatePicker)
Vue.use(VueSweetalert2)
Vue.use(VueApexCharts)
Vue.use(GSignInButton)
Vue.use(number);
Vue.use(require('moment'));
Vue.use(VueHtmlToPaper, options);
Vue.use(Dialog, {
  dialogBtnColor: '#A12528'
})
Vue.use(BootstrapVueIcons)
Vue.use(VueMask);

// Vue.use(Chart)
// Vue.use($)
 
new Vue({
  router,
  store,
  vuetify,
  fragment,
  render: h => h(App)
}).$mount('#app')
