export const BASE_URL = 'http://localhost:8082/fams/api/v1/';

// auth
export const BASE_AUTH = 'auth/';
export const ISAFE_LOGIN = BASE_AUTH + 'login/isafe';
export const GOOGLE_LOGIN_BY_TOKEN = BASE_AUTH + 'login/token/google';

// master-data
export const BASE_ROLES = 'roles';
export const BASE_USERS = 'users';
export const BASE_CURRENCIES = 'currencies';
export const BASE_DIVISIONS = 'divisions';
export const BASE_VENDORS = 'vendors';
export const BASE_PPH = 'pph';
export const BASE_BDV = 'bdv';

export const CONFIG="config";
export const UTILITY="utility";
export const CONFIG_PENGUMUMAN="PENGUMUMAN";
export const CONFIG_MAINTENANCE_MODE="MAINTENANCE_MODE";

export const BASE_PAYMENT_FIELD = 'payment-fields';


// payment
export const BASE_PAYMENTS = 'payments';
export const BASE_PAYMENT_PURPOSES = 'payment-purposes/';
export const BASE_EXPORT_PAYMENTS = 'export/payments/';
export const BASE_PAYMENT_DOCUMENTS = 'payments/documents';
export const BASE_PAYMENT_REVIEW = 'payment-review-fields/'
export const BASE_PAYMENT_REVIEW_ACTIONS = 'payment-review-actions/'
export const BASE_PAYMENT_PROCESS = 'payment-processes';
export const BASE_PAYMENT_PROCESS_PERMISSION = 'payment-process-permissions';

export const BASE_PURCHASE_ORDERS = 'po/'
export const BASE_SERVICE_ENTRYSHEETS = 'ses/'
export const BASE_SERVICE_GR_ENTRYSHEETS = 'gr/ses/'
export const BASE_SERVICE_FEATURE_FLAGS = 'feature-flags'

export const BASE_BUDGET = 'budget';

export const BASE_FINANCE_NOTIFICATION = 'finance-notification';
export const PAYMENT_RETRY_COUNT=2;

//timeout untuk bbrp API butuh panjang
export const API_TIMEOUT_LONG=60000*15; //15 menit

export const BASE_BPP = 'bpp/'