<template>
  <div id="app" class="d-flex flex-column min-vh-100 theme6" ref="baseBody">
    <!-- <login :routeActive="routeActive"></login> -->
    <!-- Admin -->
    <div>
      <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-vibrant navbar-dark" id="sidebar"
           v-if='isLoginPage && !isDownloadPage'>
    
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
                  aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <!-- Brand -->
          <a class="navbar-brand" href="/">
            <img src="./assets/img/FAMS.png" class="navbar-brand-img mx-auto" alt="...">
          </a>

          <div class="navbar-user d-md-none">

            <!-- Dropdown -->
            <div class="dropdown">

              <!-- Toggle -->
              <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <div class="avatar avatar-sm avatar-online">

                  <span class="avatar-title rounded-circle">U</span>
                </div>
              </a>

              <!-- Menu -->
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
                <!--                <a class="dropdown-item">Welcome, {{ this.username }}</a>-->
                <a @click='logout' class="dropdown-item">Logout</a>
              </div>

            </div>

          </div>

          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="sidebarCollapse">
            <!-- Divider -->
            <!-- <hr class="navbar-divider my-3"> -->

            <!-- Heading -->
            <h6 class="navbar-heading text-colors text-dark mt-3">
              Main Menu
            </h6>
            <!-- Navigation -->
            <ul class="mt-3 navbar-nav" style="z-index: 99999999">
              <li class="nav-item">
                <!-- {{ item }} -->
                <router-link tag="a" :class="`nav-link text-colors`" :to="menus[0].link">
                  <i :class="`fe fe-${menus[0].icon}`"></i> {{ menus[0].name }}
                </router-link>
              </li>
              <li class="nav-item" v-for="(item, index) in authorizedMenu" :key="`${item}${index}`">
                <router-link tag="a" :class="`nav-link text-colors`" :to="item.link">
                  <i :class="`fe fe-${item.icon}`"></i> {{ item.name }}
                </router-link>
              </li>
              <li class="nav-item">
                <!-- {{ item }} -->
                <router-link tag="a" :class="`nav-link text-colors`" :to="menus[14].link">
                  <i :class="`fe fe-${menus[14].icon}`"></i> {{ menus[14].name }}
                </router-link>
              </li>
            </ul>

            <!-- Divider -->
            <hr class="navbar-divider my-3">


          </div> <!-- / .navbar-collapse -->

        </div>
      </nav>
      <div class="main-content">
        <nav class="navbar navbar-expand-md navbar-light d-none d-md-flex fixed-top" id="topbar"
             v-if='isLoginPage && !isDownloadPage'>
             <div style="padding-left:260px; min-width: 50% " class="flex-fill">
              <span v-if="pengumuman" class=" text-warning">
              <span v-html='pengumuman'></span> <i class="fa fa-exclamation-circle" style="margin-left:0px"></i>
            </span>
             </div>
          <div class="container-fluid justify-content-center">
            <span>Welcome, {{ username }} &nbsp;</span>
            <span v-if="!!roleName" class="badge text-bg-primary">{{ roleName }} </span>
            <div class="navbar-user" style="margin-left: 20px">
              <div v-if="roleCode == 'doc-control' || roleCode == 'treasury-head'"
                   class="dropdown me-4 d-none d-md-flex">

                <!-- Toggle -->
                <a href="#" class="navbar-user-link" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false">
                  <span class="icon active">
                    <i class="fe fe-bell"></i>
                  </span>
                </a>

                <!-- Menu -->
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
                  <div class="card-body">
                    <div class="mb-3">
                      <small>
                        You were not upload the signature yet, please upload on the form below
                      </small>
                    </div>
                    <form>
                      <input type='file' v-on:change='assignFile()' class='form-control' ref='file' accept="image/png">
                      <button type="button" @click="upload()" class="btn btn-sm btn-primary right mt-2">
                        Submit
                      </button>
                    </form>
                    <!-- List group -->

                  </div>
                </div> <!-- / .dropdown-menu -->
              </div>
              <div class="dropdown">
                <a href="#" class="avatar avatar-sm avatar-online dropdown-toggle" role="button"
                   data-bs-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <img v-if="avatar != null" :src="avatar" class="avatar-img rounded-circle" alt="...">
                  <span v-else class="avatar-title rounded-circle">U</span>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a href='' @click='logout' class="dropdown-item"><span
                      class="fe fe-log-out"></span>&nbsp;&nbsp;Logout</a>
                </div>
              </div>
            </div>

          </div>
        </nav>
        <router-view style="padding-top: 70px; padding-bottom: 100px;" id="content-view"/>
        <footer class="bg-dark text-center text-lg-start fixed-bottom" v-if='!isDownloadPage'>
          <!-- Copyright -->
          <div class="text-center p-3 text-light" style="background-color: rgba(0, 0, 0, 0.2);">
            <small>Payment Application and Tracking System | Provided by Digitech - GEMS. ©️ 2022 PT. Borneo
              Indobara</small>
          </div>
          <!-- Copyright -->
        </footer>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>

import UserService from '@/services/UserService';
import login from '@/views/login.vue';
import ConfigService from '@/services/ConfigService';
import { CONFIG_MAINTENANCE_MODE, CONFIG_PEGUMUMAN, CONFIG_PENGUMUMAN } from "@/common/constant";

export default {
  name: 'HelloWorld',
  components: {login},
  data() {
    return {
      data: {},
      view: false,
      indexTemp: null,
      isLoggedIn: false,
      isLoginPage: false,
      isDownloadPage: false,
      activeIndex: null,
      avatar: null,
      roleName: null,
      signature: null,
      roleCode: null,
      pengumuman: null,
      maintenanceMode:false,
      authorizedDivisions:[],
      username: localStorage.logged_user ? JSON.parse(localStorage.logged_user).name : null,
      permission: [],
      userId: localStorage.logged_user ? JSON.parse(localStorage.logged_user).id : null,
      menus: [{
        icon: 'sidebar',
        name: 'Dashboard',
        link: '/',
        click: false,
        code: 'DASHBOARD',
        sequence: 1
      },
        {
          icon: 'users',
          name: 'Roles',
          link: '/roles',
          click: false,
          code: 'ROLES',
          sequence: 2
        },
        {
          icon: 'user',
          name: 'Users',
          link: '/users',
          click: false,
          code: 'USERS',
          sequence: 3
        },
        {
          icon: 'dollar-sign',
          name: 'Currencies',
          link: '/currencies',
          click: false,
          code: 'CURRENCIES',
          sequence: 4
        },
        {
          icon: 'layers',
          name: 'Organization',
          link: '/organizations',
          click: false,
          code: 'ORGANIZATIONS',
          sequence: 5
        },
        {
          icon: 'globe',
          name: 'Vendors',
          link: '/vendors',
          click: false,
          code: 'VENDORS',
          sequence: 6
        },
        {
          icon: 'globe',
          name: 'Non PO',
          link: '/nonpo',
          click: false,
          code: 'NON_PO',
          sequence: 7
        },
        {
          icon: 'grid',
          name: 'Payment Fields',
          link: '/payment-fields',
          click: false,
          code: 'PAYMENT_FIELDS',
          sequence: 8
        },
        {
          icon: 'file-text',
          name: 'PPH',
          link: '/pph',
          click: false,
          code: 'PPH',
          sequence: 9
        },
        {
          icon: 'clock',
          name: 'Payment Schedule',
          link: '/payment-schedule',
          click: false,
          code: 'PAYMENT_SCHEDULES',
          sequence: 10
        },
        {
          icon: 'user-check',
          name: 'Payment Schedule Approval',
          link: '/payment-schedule-approval',
          click: false,
          code: 'PAYMENT_SCHEDULE_APPROVAL',
          sequence: 11
        },
        {
          icon: 'credit-card',
          name: 'Payment',
          link: '/payments',
          click: false,
          code: 'PAYMENTS',
          sequence: 12
        },
        {
          icon: 'file-text',
          name: 'Payment Export',
          link: '/payments-export',
          click: false,
          code: 'PAYMENT_EXPORT',
          sequence: 12
        },
        {
          icon: 'user-check',
          name: 'Bank Disbursement Voucher',
          link: '/bdv',
          click: false,
          code: 'BANK_DISBURSEMENT_VOUCHER',
          sequence: 13
        },
        {
          icon: 'help-circle',
          name: 'Help',
          link: '/help',
          click: false,
          code: 'HELP',
          sequence: 14
        },
        {
          icon: 'monitor',
          name: 'Document Monitoring',
          link: '/documents',
          click: false,
          code: 'DOCUMENT_MONITORING',
          sequence: 13
        },
        {
          icon: 'dollar-sign',
          name: 'Budgets',
          link: '/budgets',
          click: false,
          code: 'BUDGETS',
          sequence: 12
        },
        {
          icon: 'dollar-sign',
          name: 'Finance Notification',
          link: '/finance-notification',
          click: false,
          code: 'FINANCE_NOTIFICATION',
          sequence: 14
        },
        {
          icon: 'gears',
          name: 'Configuration',
          link: '/config',
          click: false,
          code: 'CONFIG',
          sequence: 15
        },        
      ],
      authorizedMenu: [],
      choice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      pick: 1,
      expand: true,
      routeActive: window.location.pathname == '/login' ? null : window.location.pathname
    }
  },
  beforeMount() {
    this.getDetail()
    //dapatkan pengumuman & maintenane mode
    ConfigService.get(CONFIG_PENGUMUMAN).then(response=>{
        this.pengumuman=response.data.configValue
    });
    ConfigService.get(CONFIG_MAINTENANCE_MODE).then(response=>{
        let maintenanceMode=response.data
        if(maintenanceMode.configValue==null)
            maintenanceMode.configValue=false;
        var url=window.location.toString();
        console.log(`[App.vue] CONFIG_MAINTENANCE_MODE: ${maintenanceMode.configValue}, url: ${url}`)        
        //masih bug
        // sementara matikan, coding belum selesai
        // if(url.endsWith('/config') || url.endsWith('/maintenance') ) return;
        // console.log(`redirect`);
        //  window.location='/maintenance';
        // if(maintenanceMode.configValue)
        //   this.$router.push({
        //         path: `/maintenance`,
        //       });
       
    })
  },
  created(){
  },
  mounted() {

    this.setActive()
    this.checkSession()
  },
  watch: {
    $route() {
      this.isDownloadPage = this.$route.name?.includes('download');
      this.isLoginPage = this.$route.name !== 'login' && this.$route.name !== 'maintenance';
    }
  },
  methods: {

    assignFile() {
      this.signature = this.$refs.file.files[0];
    },

    upload() {
      UserService.upload(this.userId, this.signature)
          .then(res => {
            this.$swal("Success", "Your signature has been saved", "success")
          })
          .catch(e => {
            console.log(e);
            this.$swal("Failed", "Your signature is failed to save with following error: " + e, "error")
            if(e.response.status == 504 || e.response.status == 502){
              return this.$router.push({
                path: `/maintenance`,
              });
            }
          });
    },

    getDetail() {
      UserService.get(this.userId)
          .then(response => {
            this.data = response.data
            this.roleCode = response.data.role?.code
            this.roleName = response.data.role?.name
            this.avatar = response.data.photo
            localStorage.hasFundCenter=response.data.hasFundCenter
            const menuList = []
            if (!!response.data.role || response.data.role != null) {
              if (response.data.role.menuPermissions.length > 0) {
                this.permission = response.data.role.menuPermissions
                this.authorizedDivisions=response.data.authorizedDivisions
                this.permission.map(p => {
                  const found = (this.menus).find(element => element.code == p);
                  if (found != null) {
                    menuList.push(found)
                  }
                  this.authorizedMenu = menuList.sort((a, b) => a.sequence - b.sequence);
                })
              } else {
                this.permission = []
              }
            }
            if(!!!this.roleCode || this.authorizedDivisions.length==0){
              this.$swal("Unassigned!",  'There are no role or division assigned to this user yet. Please contact your administrator.', "error");
            }
          })
          .catch(e => {
            console.log(e);
            if(e.response.status == 504 || e.response.status == 502){
              return this.$router.push({
                path: `/maintenance`,
              });
            }
          });
    },
    checkSession() {
      const token = localStorage.getItem('bearer_token');
      if (token) {
        if (localStorage.logged_user) {
          this.username = JSON.parse(localStorage.logged_user).name;
        }
      } else {
        this.$router.push({name: 'login', params: {url: this.routeActive}});
      }
    },
    logout() {
      localStorage.removeItem('bearer_token');
      this.$router.push('login');
    },
    setActive() {
      const router = this.$router.currentRoute;
      const index = this.menus.findIndex(e => e.link === router.fullPath)
      if (this.indexTemp !== null) {
        this.menus[this.indexTemp].click = !this.menus[this.indexTemp].click;
      }
      this.menus[index].click = !this.menus[index].click;
      this.indexTemp = index;
    },
  },
}
</script>
<style>
.right {
  float: right;
}

.row {
  border: 0 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://apis.google.com/js/api:client.js');
/* MY CUSTOM CSS */
@import url('./assets/css/mycustom.css');
</style>

